@import '../colors';

.ourValues {
	background-color: #74ABBA;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 6.563vw 7.813vw 9.375vw;
	&__title {
		font-family: 'Jost';
		font-size: 3.333vw;
		font-weight: 600;
		color: white;
		margin-bottom: 6.250vw;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
		  }
	}
	&__list {
		display: flex;
		justify-content: space-around;
		width: 100%;
		@media screen and (max-width: 768px) {
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}
}

.ourCard {
	width: 9.896vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		width: 80%;
		text-align: center;
		align-items: center;
		margin: auto;
	}
	&> h3,p {
		color: white;
	}
	&__image {
		height: 8.333vw;
		position: relative;
		&--integrity {
			left: 0.977vw;
		}
		&--honestpay {
			right: 0.684vw;
		}
		@media screen and (max-width: 768px) {
			height: 20.333vw;
		}
	}
	&__title {
		font-family: 'Jost';
		font-size: 1.302vw;
		font-weight: 600;
		margin: 2.344vw 0 1.172vw;
		@media screen and (max-width: 768px) {
			font-size: 5.333vw;
		}
	}
	&__description {
		font-family: 'Nunito Sans';
		font-size: 0.938vw;
		font-weight: 500;
		text-align: center;
		@media screen and (max-width: 768px) {
			font-size: 4.333vw;
			margin-bottom: 7vw;
		}
	}
}
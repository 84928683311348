@import '../colors';

.userquestions {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	&__list {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 0 2rem;
		@media screen and (max-width: 1023px) {
			padding: 0;
			max-width: 600px;
		}
	}
}

.questioncard {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	border-radius: 7px;
	box-shadow: 0 0 15px rgba(0,0,0,0.23);
	padding: 2.3vw;
	margin: 1.6vw 0;
	width: 100%;
	&__halfbox {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&__fullbox {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&__buttonbox{
		display: flex;
		gap: 2rem;
	}
	&__sub {
		font-family: 'Jost';
		font-weight: 600;
		color: $primaryColor;
		font-size: 1.6vw;
		@media screen and (max-width: 1023px) {
			font-size: 1.1rem;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.8rem;
		}
	}
	&__text {
		font-family: 'Nunito Sans';
		font-size: 1.3vw;
		text-align: left;
		@media screen and (max-width: 1023px) {
			font-size: 1rem;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.65rem;
		}
	}
	&__statusbutton{
		background-color: $primaryColor;
		border-radius: 47px;
		width: 50%;
		align-self: center;
		margin-top: 1vw;
		width: 15vw;
		height: 2vw;
		font-size: 1.3vw;
		@media screen and (max-width: 1023px) {
			font-size: 0.8rem;
			width: 120px;
			height: 20px;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.6rem;
			width: 90px;
			height: 18px;
		}
	}
	&__button {
		background-color: #C34545 !important;
		border-radius: 47px;
		width: 50%;
		align-self: center;
		margin-top: 1vw;
		width: 15vw;
		height: 2vw;
		font-size: 1.3vw;
		@media screen and (max-width: 1023px) {
			font-size: 0.8rem;
			width: 120px;
			height: 20px;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.6rem;
			width: 90px;
			height: 18px;
		}
	}
}
@import '../colors';

.viewMyJobsCard {
	border: none;
	border-radius: 7px;
	box-shadow: 0 0 15px rgba(0,0,0,0.25);
	padding: 2.5vw;
	margin-bottom: 2vw;
	width: 100%;
	&__name {
		color: $primaryColor;
		font-weight: 600;
		font-size: 2.2vw;
		@media screen and (max-width: 768px) {
			font-size: 4.5vw;
		}
	}
	&__headline {
		color: $secondaryColor;
		font-weight: 600;
		font-size: 1.8vw;
		margin: 1vw 0;
		& span {
			color: #C34545;
		}
		@media screen and (max-width: 768px) {
			font-size: 4vw;
		}
	}
	&__text {
		color: $primaryColor;
		&--description {
			font-size: 1.4vw;
			@media screen and (max-width: 768px) {
				font-size: 3.8vw;
			}
		}
		&--status {
			font-weight: 600;
			margin: 0;
			font-size: 1.8vw;
			@media screen and (max-width: 768px) {
				font-size: 4vw;
			}
		}
	}
	&__subBox {
		display: flex;
		justify-content: space-between;
		margin: 1.5vw 0 0 0;
		
	}
	&__rate {
		color: $secondaryColor;
		font-weight: 600;
		margin: 0;
		font-size: 1.5vw;
		@media screen and (max-width: 768px) {
			font-size: 3.8vw;
		}
	}
	&__location {
		color: $primaryColor;
		font-weight: 600;
		margin: 0;
		font-size: 1.5vw;
		@media screen and (max-width: 768px) {
			font-size: 3.8vw;
		}
	}
	&__button {
		background-color: #C34545;
		border-radius: 47px;
		width: 18vw;
		height: 3vw;
		margin: 1.8vw 0 0 0;
		font-size: 1.4vw;
		@media screen and (max-width: 768px) {
			font-size: 3.404vw;
			width: 49vw!important;
			height: 7.698vw!important;
		  }
	}
}
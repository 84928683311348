@import '../colors';

.editButton {
	border-radius: 45px;
	height: 3vw;
	width: 17.969vw;
	font-size: 1.2vw;
	margin-bottom: 1vw;
	background-color: $primaryColor;
	margin-right: 5.396vw;
	@media screen and (max-width: 768px) {
		font-size: 3.104vw;
		width: 49vw!important;
		height: 7.698vw!important;
		margin-right: 0;
		margin: 1.3vw 0;
  }
	&--left {
		margin-right: 0;
	}
	&--active {
		background-color: #74ABBA;
	}
	&__container {
		display: flex;
		justify-content: flex-start;
		@media screen and (min-width: 768px){
			width: 65%;
		}
		margin-top: 3.802vw;
		margin-bottom: 7.646vw;
		@media screen and (max-width: 768px) {
			display: flex;
			flex-direction: column;
			margin: 5vw 0;
	  }
	}
}
.dashinfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 3rem;
	&__jobform {
		width: 60%;
		text-align: center;
		padding: 1vw;
		display: flex;
		flex-direction: column;
		
		
	}
	&__captchabox {
		display: flex;
		justify-content: center;
		margin: 1.3vw 0;
		align-self: center;
	}
	&__title {
		font-family: 'Nunito Sans';
		font-weight: 600;
		margin: 0;
		margin-right: 1vw;
		@media screen and (max-width: 768px) {
			font-size: 5.104vw;
	  }
	}
	&__warning {
		font-family: 'Nunito Sans';
		margin: 0.1vw 0 1vw 0;
		margin-right: 1vw;
		@media screen and (max-width: 768px) {
			margin-top: 1vw;
			font-size: 3.904vw;
	  }
	  &--underline{
		text-decoration: underline;
	  }
	}
	&__label {
		margin-right: 1vw;
		@media screen and (max-width: 768px) {
			font-size: 5.104vw;
	  }
	  &--aff {
	  	margin-top: 1rem;
	  	display: block;
	  	margin-bottom: 0.5rem;
	  }
	}
	&__input {
		padding: 0.2rem 0.5rem;
		&--aff{
			width: 200px;
			margin: auto;
		}
	}
	&__button {
		display: block;
		border-radius: 47px;
		margin-top: 1.5vw;
		margin-left: auto;
		margin-right: auto;
		width: 10vw;
		height: 2vw;
		font-size: 1vw;
		background-color: $primaryColor;
		@media screen and (max-width: 768px) {
			font-size: 3.104vw;
			width: 49vw!important;
			height: 7.698vw!important;
			margin-right: 0;
			margin: 1.3vw auto;
	  }
	}
}

.tnc {
	margin: 3vw 0 1vw;
	
	

	@media screen and (max-width: 768px) {
		
  }
	& * {
		text-align: left;
	}
	&__title {
		text-align: center;
		margin-bottom: 1.5vw;
		font-size: 2vw;
		margin-right: 1vw;
		font-family: "Jost";
		color: #28485B;
		font-weight:600;
		@media screen and (max-width: 768px) {
			font-size: 5.104vw;
	  }
	}
	&__subtitle {
		font-size: 1.1vw;
		margin-right: 1vw;
		font-family: "Jost";
		color: #28485B;
		@media screen and (max-width: 768px) {
			font-size: 5.104vw;
	  }
	}
	&__text {
		font-size: 1vw;
		@media screen and (max-width: 768px) {
			font-size: 3.504vw;
	  }
	}
	&__bold {
		font-weight: bold;
	}
	&__inputbox {
		align-self: center;
	}
	&__label {
		margin-right: 1vw;
		font-size: 1vw;
		@media screen and (max-width: 768px) {
			font-size: 2.904vw;
			margin: auto;
			vertical-align: top;
			margin-top: 1vw;
			margin-left: 1vw;
	  }
	}
}

.fileform {
	@media screen and (max-width: 768px) {
		display: none;
	}
	display: flex;
	flex-direction: column;
	// align-items: center;
	width: 100%;
	margin: 1vw 0 3vw 0;
	&__subtitle {
		font-family: 'Jost';
		font-size: 1.719vw;
		color: $primaryColor;
		// font-weight: 600;
		margin-bottom: 0.5vw;
		padding: 0;
	}
	&__fileinput {
		font-size: 0.990vw;
		margin-bottom: 3vw;
		margin-top: 2vw;
		border: 4px solid #868686;
		border-radius: 18px;
		background-color: #F0EFEF;
		width: 39.188vw;
		height: 17.656vw;
		display: flex;
		justify-content: center;
		align-items: center;
		border-style: dashed;
	}
	&__text {
		font-family: 'Nunito Sans';
		font-size: 1.458vw;
		color: $primaryColor;
		display: inline;
	}
	&__sent {
		font-family: 'Nunito Sans';
		font-size: 1.458vw;
		color: $primaryColor;
		display: inline;
		text-decoration: underline;
	}
	&__link {
		font-family: 'Nunito Sans';
		font-size: 1.458vw;
		color: $primaryColor;
		display: inline;
		text-decoration: underline;
		cursor: pointer;
	}
	&__dragBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	&__currentBox {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24%;
		height: 17.656vw;
		margin-bottom: 3vw;
		margin-top: 2vw;
		border: 4px solid #868686;
		border-radius: 18px;
		background-color: #F0EFEF;
		border-style: dashed;
	}
	&__image {
		width: 100%;
	}
}
@import '../colors';

.cleanerdata {
	width: 100%;
	max-width: 1200px;
	padding-left: 2rem;
	@media screen and (max-width: 1023px) {
		padding-left: 0;
	}
	&__titlebox {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 4rem;
		@media screen and (max-width: 1023px) {
			margin-bottom: 1rem;
		}
	}
	&__title {
		font-family: 'Nunito Sans';
		font-weight: 600;
		color: $primaryColor;
		margin-bottom: 0;
		@media screen and (max-width: 1023px) {
			font-size: 1.5rem;
			margin-bottom: 0;
			padding-top: 0.15rem;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.9rem;
			margin-bottom: 0;
			padding-top: 0.15rem;
		}
	}
	&__sortbox {
		display: flex;
		align-items: flex-end;
	}
	&__label {
		font-family: 'Avenir';
		font-size: 1rem;
		color: $primaryColor;
		margin-right: 0.5rem;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
		}
	}
	&__select {
		background-color: #74ABBA;
		border: none;
		border-radius: 30px;
		padding: 0.1rem 0.3rem;
		color: white;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
			padding: 0.2rem 0.4rem;
		}
	}
	&__searchbox {
		width: 100%;
		height: 2.5rem;
		border-radius: 45px;
		overflow: hidden;
		box-shadow: 0 3px 30px rgba(0,0,0,0.16);
		display: flex;
		margin-bottom: 2rem;
		@media screen and (max-width: 767px) {
			height: 1.5rem;
			margin-bottom: 1rem;
		}
	}
	&__input {
		width: 95%;
		border: none;
		height: 2.5rem;
		padding: 0.5rem 1.5rem;
		&:focus {
			outline: none;
		}
		@media screen and (max-width: 767px) {
			height: 1.5rem;
			width: 90%;
			font-size: 0.7rem;
		}
	}
	&__search {
		width: 5%;
		height: 100%;
		background-color: #D3D3D3;
		display: flex;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: 767px) {
			width: 10%;
		}
	}
	&__icon {
		width: 40%;
	}
	&__downloadbox{
	display: flex;
	flex-direction: column;
	text-align: right;
	}
	&__downloadbutton
    {
    border-radius: 47px;
    
		color: $primaryColor;
		text-decoration: underline;
		margin: auto;
	margin: 0 .4rem;
    font-size: 1.2vw;
    font-weight: 400;

   }
}
@import '../colors';

.profile {
	background-color: #C1E5EE;
	display: flex;
	padding: 3.906vw 7.031vw;
	justify-content: space-between;
	&__detailBox {

	}
	&__title {
		font-family: 'Jost';
		font-size: 2.344vw;
		font-weight: 600;
		margin-bottom: 2.344vw;
		color: $primaryColor;
		@media screen and (max-width: 768px) {
			font-size: 4.904vw;
		  }
		  &--subtext{
			font-size: 1.514vw;
			font-weight: 300;
		  }
	}
	&__subtitle {
		font-family: 'Nunito Sans';
		font-size: 1.875vw;
		font-weight: 600;
		margin-bottom: 1.172vw;
		color: $primaryColor;
		&--head {
			font-weight: 700;
			margin-bottom: 2.344vw;
		}
		&--link {
			text-decoration: underline;
		}
		@media screen and (max-width: 768px) {
			font-size: 3.904vw;
	  }
	}
	&__contactBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 3.125vw;
		position: relative;
		width: 18.604vw;
		@media screen and (max-width: 768px) {
			width: 26.604vw;
			margin-bottom: 19vw;
			padding-top: 0;
			&--userDashboard{
				flex-direction: row;

			}
	  }
	}
	&__button {
		width: 16.406vw ;
		height: 3.698vw ;
		margin: 0 ;
		border-radius: 49px;
		font-size: 1.302vw;
		font-family: "Jost";
		font-weight: 600;
		background-color: $primaryColor;
		a{
			color: white;
			text-decoration: none;
			&:hover
			{
				text-decoration: none;
			}
		}
		@media screen and (max-width: 768px) {
			font-size: 3.404vw;
			width: 35vw!important;
			height: 7.698vw!important;
	  }

	}
	&__checkBox {
		width: 18.604vw;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 0.781vw 0;
		position: absolute;
		top: 8.031vw;
		@media screen and (max-width: 768px) {
			top: 15.031vw;
		  }
		margin-bottom: 6vw;
		&--police {
			top: 12.125vw;
			@media screen and (max-width: 768px) {
				width: 33.604vw;
				margin-bottom: 1vw;
				margin-top: 10vw;
				top: 22.125vw;
			  }
		}
		@media screen and (max-width: 768px) {
			width: 33.604vw;
			margin-bottom: 1vw;
			margin-top: 2vw;
		  }
	}
	&__icon {
		height: 2.917vw;
		margin-right: 0.938vw;
		@media screen and (max-width: 768px) {
			height: 5.917vw;
			margin-bottom: 1vw;
		  }
	}
	&__validText {
		color: #647D41;
		font-family: 'Nunito Sans';
		font-size: 1.823vw;
		@media screen and (max-width: 768px) {
			font-size: 2.904vw;
		  }
	}
	&__link {
		font-size: 1.8vw;
		color: $primaryColor;
		text-decoration: underline;
		@media screen and (max-width: 768px) {
			font-size: 2.904vw;
		  }
	}
	&__freetext {
		font-size: 2vw;
		@media screen and (max-width: 768px) {
			font-size: 2.904vw;
		  }
	}
}
@import '../colors';

.usercard {
	padding: 1rem 0;
	box-shadow: 0 0 15px rgba(0,0,0,0.23);
  margin-top: 2.604vw;
  padding: 2.240vw 3.021vw 1.875vw 2.604vw;
  border-radius: 7px;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 1.5rem auto;
	@media screen and (max-width: 1023px) {
		padding: 2rem;
	}
	@media screen and (max-width: 767px) {
		padding: 1.5rem;
	}
	&__title {
		margin: 0.5rem 0;
		font-size: 1.8vw;
		text-align: left;
		color: $primaryColor;
		text-align: left;
		font-family: 'Nunito Sans';
		font-weight: 700;
		@media screen and (max-width: 1023px) {
			font-size: 1.5rem;
			margin: 0;
		}
		@media screen and (max-width: 767px) {
			font-size: 1.2rem;
			margin: 0;
		}
	}
	&__subtitle {
		font-size: 1.7vw;
		color: $primaryColor;
		margin-top: 1.1vw;
		font-weight: 600;
		font-family: 'Jost';
		@media screen and (max-width: 1023px) {
			font-size: 1.3rem;
			margin: 0.7rem 0 0 0;
		}
		@media screen and (max-width: 767px) {
			font-size: 1rem;
			margin: 0.5rem 0 0 0;
		}
	}
	&__job {
		margin: 0.5rem 0;
		color: $secondaryColor;
		font-size: 1.5vw;
		text-transform: capitalize;
		text-align: left;
		font-family: 'Nunito Sans';
		font-weight: 600;
		@media screen and (max-width: 1023px) {
			font-size: 1.1rem;
			margin: 0.5rem 0;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.9rem;
			margin: 0.3rem 0;
		}
	}
	&__resume {
		margin: 0.5rem 0;
		color: $primaryColor;
		font-size: 1.3vw;
		text-align: left;
		font-family: 'Nunito Sans';
		text-decoration: underline;
		@media screen and (max-width: 1023px) {
			font-size: 1rem;
			margin: 0.5rem 0;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.8rem;
			margin: 0.3rem 0;
		}
	}
	&__email {
		margin: 0.5rem 0;
		color: $primaryColor;
		font-size: 1.3vw;
		text-align: left;
		font-family: 'Nunito Sans';
		@media screen and (max-width: 1023px) {
			font-size: 1rem;
			margin: 0.5rem 0;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.8rem;
			margin: 0.3rem 0;
		}
	}
	&__images {
		width: 500px;
		display: block;
		margin-bottom: 1rem;
	}
	&__buttonDivGridThree{
		margin: 0 auto;
		width: 100%;
		display: grid;
		grid-template-columns: auto auto auto;
		margin-top: 1vw;
	}
	&__buttonDivGridTwo{
		margin: 0 auto;
		width: 100%;
		display: grid;
		grid-template-columns: auto auto ;
		margin-top: 1vw;
	}
	&__button {
		background-color: $primaryColor;
		border-radius: 47px;
		width: 11vw;
		margin-top: 1vh;
		height: 2.2vw;
		font-size: .9vw;
		font-family: "Nunito Sans";
		@media screen and (max-width: 1023px) {
			width: 120px;
			height: 24px;
			font-size: 0.7rem;
		}
		@media screen and (max-width: 767px) {
			width: 90px;
			height: 20px;
			font-size: 0.6rem;
		}
		&--verified {
			background-color: #74ABBA;
		}
		&--delete {
			background-color: #C34545;
			align-self: center;
			margin-top: 1.7vw;
		}
	}
	&__verificationbox {
		display: flex;
		width: 100%;
		justify-content: center;
	}
	&__innerver {
		display: flex;
		flex-direction: column;
		width: 50%;
		&> p {
			margin: 0;
			@media screen and (max-width: 767px) {
				font-size: 0.7rem;
			}
		}
	}
	&__vertext {
		font-size: 1.2vw;
		margin-top: 0.6vw;
		@media screen and (max-width: 1023px) {
			font-size: 0.9rem;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
		}
	}
	&__policeLink{
		&--noLink{
			color: black;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
		}
	}
	&__whmisLink{
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
		}
	}
}
@import '../colors';
@import '../sizes';
.ourStory {
	&__hero {
		width: 100%;
		height: 100%;
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
	&__textBox {
		padding: 7.813vw 0 9.375vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		@media screen and (max-width: $tablet) {
			
		  }
	}
	&__title {
		font-family: 'Jost';
		font-size: 3.033vw;
		font-weight: 600;
		color: $primaryColor;
		margin-bottom: 3.125vw;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
		  }
	}
	&__text {
		width: 63.594vw;
		text-align: center;
		font-family: 'Nunito Sans';
		font-size: 1.302vw;
		font-weight: 500;
		
		@media screen and (max-width: 768px) {
			font-size: 3.994vw;
		}
	}
}
@import '../colors';

.admin {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	&__banner{
		height: 12.2vw;
		background-color: $primaryColor;
		padding: 6vw 0 0 7.292vw;
		width: 100%;
		text-align: left;
		@media screen and (max-width: 767px) {
			padding: 2rem 0 0 1.5rem;
			height: 70px;
		}
	}
	&__title {
		font-family: 'Jost';
		font-size: 2.1vw;
		font-weight: 600;
		margin-bottom: 2.344vw;
		color: white;
		@media screen and (max-width: 767px) {
			font-size: 0.8rem;
		}
	}
	&__filterbox {

	}
	&__list {
		align-items: center;
		width: 100%;
		@media screen and (max-width: 1023px) {
			max-width: 600px;
		}
	}
	&__searchBar{
		height: 65px;
		width: 40%;
		padding: 0 1.5%;
		border-radius: 0%;
		font-size: 1.5vw;
		font-family: 'Nunito Sans';
		border: 1px solid black;
		border-radius: 50px;
		box-shadow: none!important;
		margin: 1vw 0;
		&:focus{
			outline: none !important;
		}
	}
	&__selectsection {
		display: flex;
		justify-content: center;
		width: 100%;
		max-width: 1400px;
		padding: 6rem 2rem;
		@media screen and (max-width: 1023px) {
			padding: 3rem 2.313rem;
		}
		@media screen and (max-width: 767px) {
			padding: 3rem 1rem;
		}
	}
	&__selectbox {
		width: 100%;
		max-width: 1500px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-content: space-between;
		height: 550px;
		flex-wrap: wrap;
		@media screen and (max-width: 1023px) {
			height: 770px;
			flex-direction: row;
		}
		@media screen and (max-width: 767px) {
			flex-direction: column;
			height: auto;
		}
	}
}

.noaccess {
	width: 100%;
	padding: 3rem 4rem;
}

.sidebar {
	width: 340px;
	background-color: #E1EFF1;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 20px 4rem 20px;
	@media screen and (max-width: 1023px) {
		display: none;
	}
	&__title {
		margin: 1.875rem 0;
		color: $primaryColor;
		font-family: 'jost';
		font-weight: 600;
		align-self: flex-start;
	}
}

.selectcard {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 300px;
	height: 250px;
	border-radius: 8px;
	box-shadow: 0 0 15px rgba(0,0,0,0.23);
	overflow: hidden;
	background-color: white;
	cursor: pointer;
	@media screen and (max-width: 1023px) {
		width: 274px;
		height: 197px;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
		height: auto;
		margin-bottom: 2rem;
	}
	&--active {
		background-color: #74ABBA;
	}
	&--menu {
		margin-bottom: 2.5rem;
	}
	&__image {
		width: 100%;
	}
	&__text {
		color: $primaryColor;
		margin: 0;
		font-family: 'Jost';
		font-weight: 600;
		height: 100%;
		display: flex;
		align-items: center;
		&--active {
			color: white;
		}
	}
}

.verifyusers {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 2rem;
	@media screen and (max-width: 1023px) {
		padding: 0;
	}
}
.filterbox {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 2vw 0;
	@media screen and (max-width: 1023px) {
		margin: 0.5rem 0;
	}
	&__label {
		font-size: 1.3vw;
		margin-right: 0.2vw;
		@media screen and (max-width: 1023px) {
			font-size: 0.8rem;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
		}
	}
	&__filter {
		border-radius: 7px;
		width: 12vw;
		margin-right: 1vw;
		height: 1.5vw;
		font-size: 1.1vw;
		@media screen and (max-width: 1023px) {
			font-size: 0.7rem;
			height: 1.1rem;
			width: 110px;
		}
		@media screen and (max-width: 767px) {
			font-size: 0.6rem;
			height: 1rem;
			width: 70px;
		}
	}
	&__button {
		background-color: $primaryColor;
		border-radius: 47px;
		width: 14vw;
		height: 2.2vw;
		font-size: 1.2vw;
		margin-right: 1vw;
		margin-bottom: 1.4vw;
		&--active {
			background-color: #74ABBA;
		}
	}
}
@import '../colors';

.userreviews {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	&__list {
		width: 50%;
	}
	&__inputbox {
		width: 50%;
	}
	&__input {
		width: 100%;
		height: 2.5vw;
		border-radius: 4px;
		outline: none;
		padding: 0.5vw;
	}
	&__button {
		border-radius: 47px;
		background-color: $primaryColor;
		width: 12vw;
		height: 2.2vw;
		font-family: 'Jost';
		font-weight: 600;
		font-size: 1.3vw;
		margin-top: 1vw;
		margin: 1vw 2vw 0;
	}
	&__label {
		margin-top: 2vw;
		font-size: 1.3vw;
		padding: 0;
		width: 50%;
		text-align: left;
	}
}

.reviewcard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 7px;
	box-shadow: 0 0 15px rgba(0,0,0,0.23);
	padding: 2.3vw;
	margin: 1.6vw 0;
	&__infobox {
		display: flex;
		width: 100%;
		margin-bottom: 1.5vw;
	}
	&__infosubbox {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	&__title {
		font-family: 'Jost';
		font-size: 1.8vw;
		font-weight: 600;
		color: $primaryColor;
	}
	&__sub {
		font-family: 'Nunito Sans';
		font-weight: 600;
		font-size: 1.3vw;
		margin-bottom: 0.3vw;
	}
	&__text {
		font-family: 'Nunito Sans';
		font-size: 1.3vw;
		text-align: left;
	}
	&__button {
		background-color: #C34545 !important;
		border-radius: 47px;
		width: 50%;
		align-self: center;
		margin-top: 1vw;
		width: 15vw;
		height: 2vw;
		font-size: 1.3vw;
	}
}
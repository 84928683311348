@import '../colors';

.viewMyJobs {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 0;
	&__title {
		color: $primaryColor;
		font-family: 'Jost';
		font-size: 3vw;
		font-weight: 600;
		margin-bottom: 3vw;
		align-self: flex-start;
		@media screen and (max-width: 768px) {
			font-size: 6vw;
		}
	}
	&__joblist {
		width: 80%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__emptybox {
		display: flex;
		justify-content: center;
		align-items: center;
		// width: 50%;
		border: none;
		border-radius: 7px;
		box-shadow: 0 0 15px rgba(0,0,0,0.25);
		padding: 1rem;
		width: 80%;
		height: 10vw;
	}
	&__emptyTitle {
		color: $primaryColor;
		font-size: 1.4vw;
		font-family: 'Nunito Sans';
		font-weight: 600;
		@media screen and (max-width: 768px) {
			font-size: 3.2vw;
			margin-top: 1vw;
		}
	}
}
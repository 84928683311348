@import '../colors';

.whoWeAre {
	display: flex;
	justify-content: center;
	padding: 12.500vw 4.688vw;
	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
		text-align: center;
		padding: 12.500vw 4.688vw;
	}
	&__textBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 36.510vw;
		margin-right: 3.5vw;
		@media screen and (max-width: 768px) {
			width: 66.510vw;
			margin: auto;
		}
	}
	&__title {
		color: $primaryColor;
		font-family: 'Jost';
		font-size: 2.865vw;
		font-weight: 600;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
		  }
	}
	&__text {
		text-align: center;
		font-family: 'Nunito Sans';
		font-size: 1.302vw;
		font-weight: medium;
		line-height: 2.083vw;
		margin: 3.125vw 0 4.688vw 0;
		width: 36.510vw;
		@media screen and (max-width: 768px) {
			font-size: 3.533vw;
			margin: 0 10vw ;
			margin-bottom: 4vw;
			line-height: 5.083vw;
			width: 100%;
			font-weight: 600;
			
		}
	}
	&__button {
		width: 19.844vw;
		height: 3.698vw;
		border-radius: 47px;
		color: white;
		font-family: 'Jost';
		font-size: 1.302vw;
		font-weight: 600;
		background-color: $primaryColor;
			@media screen and (max-width: 768px) {
			font-size: 3.404vw;
			width: 39vw!important;
			height: 7.698vw!important;
		  }
	}
	&__image {
		width: 28.750vw;
		margin-left: 3.5vw;
		@media screen and (max-width: 768px) {
			margin: auto;
			margin-bottom: 8vw;
			width: 50.750vw;
		  }
		
	}
}
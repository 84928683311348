@import '../colors';

.loginmodal {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	background-color: white;
	width: 400px;
	height: 530px;
	overflow: none;
	border-radius: 25px;
	
	@media screen and (max-width: 768px) {
		width: 100%;
	}
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0.7);
		width: 100%;
		height: 100%;z-index: 20;
	}
	&__contentbox {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 3rem;
		// max-height: 90vh;
		overflow-y: auto;
		
	}
	&__logo {
		width: 39%;
		margin: auto;
		margin-bottom: 3vw;
	}
	&__title {
		font-family: 'Nunito Sans';
		font-size: 1.5rem;
		font-weight: bold;
		margin-bottom: 1.5rem;
	}
	&__button {
		width: 50%;
		height: 2.5rem;
		margin: auto;
		border-radius: 49px;
		font-size: 1rem;
		font-family: 'Nunito Sans';
		font-weight: 600;
		margin-top: 2rem;
		background-color: $primaryColor;
	}
}
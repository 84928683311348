@import '../colors';

body {
    height: 100%;
}
html {
    min-height:100%;
    position: relative;
}

.jobmodal {
    position: absolute;
    top: 5%;
    left: 13%;
    background-color: white;
    width: 72.708vw;
    border-radius: 25px 0px 0px 25px;

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.7);
        width: 100%;
        height: 100%;
        z-index: 100;
    }
    &__contentbox {
        display: flex;
        flex-direction: column;
        padding: 3.125vw;
        max-height: 90vh;
        
        overflow-y: auto;
        @media screen and (max-width: 768px) {
            min-height: 1vh;
            height: 100%;
        }
    }
    &__title {
        font-family: 'Nunito Sans';
        font-size: 2vw;
        color: $primaryColor;
        font-weight: bold;
        margin-bottom: 1.563vw;
        @media screen and (max-width: 768px) {
            font-size: 7vw;
        }
    }
    &__headline {
        font-family: 'Nunito Sans';
        font-size: 1.563vw;
        font-weight: bold;
        color: $secondaryColor;
        margin: 0 0 2vw 0;
        @media screen and (max-width: 768px) {
            font-size: 3.5vw;
        }
    }
    &__criteria {
        font-size: 1.5vw;
        font-family: 'Nunito Sans';
        color: $primaryColor;
        font-weight: 600;
        margin: 0;
        @media screen and (max-width: 768px) {
            font-size: 3.5vw;
            margin-top: 2vw;
        }
    }
    &__arrayBox {
        display: flex;
    }
    &__description {
        font-family: 'Nunito Sans';
        font-size: 1.250vw;
        line-height: 1.823vw;
        margin: 0.8vw 0.3vw 1.3vw 0;
        &--array {
        }
        @media screen and (max-width: 768px) {
            font-size: 4.1vw;
            line-height: 4.963vw;
        }
    }
    &__subBox {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
    &__detailBox {
        display: flex;
    }
    &__detail {
        color: black;
        padding: 0 0.781vw;

        &--middle {
            border-left: 2px solid black;
            border-right: 2px solid black;
        }
    }
    &__button {
        width: 18.698vw;
        height: 4.271vw;
        border-radius: 49px;
        font-size: 1.706vw;
        font-family: 'Jost';
        font-weight: 600;
        margin: 1.563vw auto 3.125vw;
        padding: 5px 0;
        background-color: $secondaryColor;
        @media screen and (max-width: 968px) {
            margin-top: 3vw;
            font-size: 3.404vw;
            width: 39vw!important;
            height: 7.698vw!important;
          }
    }
    &__subtitle {
        text-align: center;
        border-top: 2px solid #29485A;
        padding-top: 3.125vw;
        font-family: 'Jost';
        font-size: 2.344vw;
        @media screen and (max-width: 768px) {
            font-size: 4.5vw;
        }
    }
    &__price{
        font-family: 'Nunito Sans';
        font-size: 1.563vw;
        font-weight: bold;
        color: $secondaryColor;
        margin: 2vw 0 2vw 0;
        @media screen and (max-width: 768px) {
            font-size: 3.5vw;
        }
    }
    &__SFL{
        font-family: 'Nunito Sans';
        font-size: 1.563vw;
        font-weight: bold;
        color: black;
        
        margin: 2vw 1vw 2vw 1vw;
        @media screen and (max-width: 768px) {
            font-size: 3.5vw;
            margin: 0 ;
            margin-bottom: 3vw;
        }
        &--middle {
            border-left: 2px solid black;
            border-right: 2px solid black;
            padding-top: 2vw;
            @media screen and (max-width: 768px) {
                font-size: 3.5vw;
                
            }
        }        
    }
}
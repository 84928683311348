@import '../colors';

.lookingCleaner {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 6.250vw 0 3.906vw;
	& > h2, h3 {
		color: #2F2F2F;
	}
	&__title {
		font-family: 'Jost';
		font-size: 3.125vw;
		font-weight: 600;
		@media screen and (max-width: 768px) {
			font-size: 6.104vw;
			text-align: center;
		  }
	}
	&__subtitle {
		font-family: 'Nunito Sans';
		font-size: 2.083vw;
		font-weight: 600;
		margin: 3.125vw 0;
		@media screen and (max-width: 768px) {
			font-size: 3.333vw;
			text-align: center;
		}
	}
	&__button {
		font-size: 1.302vw;
		font-weight: bold;
		border-radius: 47px;
		width: 19.844vw;
		height: 3.698vw;
		font-family: 'Nunito Sans';
		background-color: $secondaryColor;
		@media screen and (max-width: 768px) {
			margin-top: 4vw;
			font-size: 3.404vw;
			width: 39vw!important;
			height: 7.698vw!important;
		  }
	}
}
@import '../colors';
@import '../fonts';

.testimonials {
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #74ABBA;
	align-items: center;
	padding: 6.250vw 3.125vw;
	&--about {
		background-color: white;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
		  }
	}
	&__title {
		color: white;
		font-family: 'Jost';
		font-weight: 600;
		font-size: 2.865vw;
		text-align: center;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
		  }
		&--about {
			color: $primaryColor;
			font-size: 3.333vw;
			@media screen and (max-width: 768px) {
				font-size: 7.904vw;
			  }
		}
	}
	&__list {
		box-sizing: border-box;
		list-style-type: none;
		display: flex;
		justify-content: space-around;
		padding: 0;
		margin: 5.859vw 0;
		width: 100%;
	}
	&__button {
		width: 19.844vw !important;
		height: 3.698vw !important;
		border-radius: 47px;
		font-size: 1.302vw;
		font-weight: bold;
		font-family: 'Nunito Sans';
		background-color: $primaryColor;
		@media screen and (max-width: 968px) {
			margin-top: 3vw;
			font-size: 3.404vw;
			width: 39vw!important;
			height: 7.698vw!important;
		  }
	}
	&__clickText {
		align-self: flex-end;
		margin-right: 2.4vw;
		border: none;
		background-color: rgba(0,0,0,0);
		text-decoration: underline;
		cursor: pointer;
		font-size: 1.2vw;

		color: black;
		font-family: "Nunito Sans";
		@media screen and (max-width: 968px) {

			font-size: 3.546vw;

		  }
	}
	&__carouselButton {
		background-color: rgba(0,0,0,0);
		border: none;
		color: black;
		font-weight: 600;
		font-size: 2vw;
		text-shadow: 2px 2px grey 0.5;
		&--hide {
			display: none;
			@media screen and (max-width: 968px) {
				display: inline;
		  }
		}
		@media screen and (max-width: 968px) {
			font-size: 4.146vw;
		  }
	}
	&__placeholderText {
		font-family: 'Jost';
		font-size: 2rem;
		margin: 10vw 0;
	}
}

.testCard {
	background-color: white;
	padding: 4.375vw 3.125vw 0;
	border-radius: 25px;
	width: 26.458vw;
	height: 18.281vw;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	margin: 5.885vw 0;
	@media screen and (max-width: 968px) {
		width: 60.458vw;
		height: 48.281vw;
	  }

	&__quote {
		width: 1.953vw;
		position: absolute;
		top: 1.270vw;
		left: 2.441vw;
		@media screen and (max-width: 968px) {
			margin-top: .6vw;
			width: 3.5vw;
		  }
	}
	&__button {
		width: 2.25vw;
		background-color: rgba(0,0,0,0);
		border: none;
		position: absolute;
		top: 1vw;
		&--edit {
			right: 4.5vw;
		}
		&--del {
			right: 1.8vw;
		}
	}
	&__icon {
		width: 2.25vw;

	}
	&__text {
		font-family: 'Jost';
		font-weight: medium;
		font-size: 1.146vw;
		line-height: 1.4vw;
		text-align: center;
		position: relative;
		overflow: hidden;
		display: block;
		@media screen and (max-width: 968px) {
			margin-top: 2vw;
			font-size: 3.746vw;
			line-height: 4.4vw;
			height: 28vw;
		  }
    display: -webkit-box;
    height: 7vw;
    margin: 0 auto 1rem;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
	
    text-overflow: ellipsis;
		&--bold {
			font-weight: bold;
			// margin-top: 1.875vw;
		}
	}

	&__leftBy {
		font-family: 'Nunito Sans';
		font-size: 1.146vw;
		text-align: center;
		font-weight: bold;
		margin-top: 0.833vw;
		@media screen and (max-width: 968px) {
			font-size: 4.146vw;
			line-height: 4.4vw;
		  }
	}
}

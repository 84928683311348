@import '../../colors';

.chat{
    font-family: 'Nunito Sans';
    &__box{
        padding: .3rem 0;
    }
    &__currentbox{

    }
    &__recipientbox{

    }
    &__currenttext{

    }
    &__recipienttext{

    }
    &__sendbuttonbox{
        width: 90%;
        margin: 2rem auto;
        
    }
    &__sendinput{
        width: 75%;
        height: 100%;
        padding: .4rem .7rem;
        border-radius: .2rem 0  0 .2rem;
    }
    &__sendbutton{
        width: 25%;
        height: 100%;
        background-color: $primaryColor;
        padding: .46rem 0;
        border-radius: 0 .2rem .2rem 0 ;
        border-right: 0;
    }
    &__sendsuggestion{
        display: flex;
        width: 90%;
        gap: 2rem;
        margin: auto;
        margin-top: 4rem;
        @media screen and (max-width: 768px) {
           flex-direction: column;
          }
    }
    &__sendsuggestion--button{
        background-color: $primaryColor;
        padding: 1rem;
        
        border-right: 0;
    }
}

.rce-mbox-text{
    font-size: 16px!important;
    font-weight: 400!important;
}
@import '../colors';

.alertmodal {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	background-color: #F5F5F5;
	width: 500px;
	height: 300px;
	border-radius: 25px;
	@media screen and (max-width: 768px) {
		width: 375px;
		height: 250px;
	  }
	box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0.7);
		width: 100%;
		height: 100%;
		z-index: 100;
	}
	&__contentbox {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 4rem;
	
	}
	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	&__label {
		font-family: 'Nunito Sans';
		font-size: 1.7rem;
		font-weight: 600;
	}
	&__input {
		width: 80%;
		margin: 2rem 0;
		resize: none;
		height: 210px;
		border: 1px solid silver;
		padding: 0.5rem;
		font-size: 1rem;
		font-family: 'Nunito Sans';
	}
	&__button {
		background-color: $primaryColor;
		border-radius: 49px;
		width: 230px !important;
		height: 51px !important;
		margin-top: 1rem;
		border: none;
		font-family: 'Nunito Sans';
		font-size: 1.2rem;
		font-weight: 600;
	}
}
@import '../colors';

.userdatacard {
	box-shadow: 0 0 15px rgba(0,0,0,0.23);
	border-radius: 7px;
	border: none;
	width: 100%;
	padding: 1.5rem 2rem;
	margin-bottom: 1rem;
	@media screen and (max-width: 767px) {
		padding: 1rem 1.5rem;
	}
	&__row {
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start;
		}
		&--buttons {
			@media screen and (max-width: 767px) {
				flex-direction: row;
			}
		}
	}
	&__title {
		color: $primaryColor;
		font-family: 'Nunito Sans';
		font-size: 1.5rem;
		font-weight: 600;
		@media screen and (max-width: 767px) {
			font-size: 1rem;
		}
	}
	&__subtitle {
		color: $secondaryColor;
		font-family: 'Nunito Sans';
		font-size: 1.1rem;
		font-weight: 600;
		padding-top: 0.3rem;
		@media screen and (max-width: 767px) {
			font-size: 0.8rem;
		}
	}
	&__delete {
		font-family: 'Nunito Sans';
		color: #C34545;
		border: none;
		background-color: rgba(0,0,0,0);
		text-decoration: underline;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
		}
	}
	&__button {
		border: none;
		background-color: rgba(0,0,0,0);
		color: $primaryColor;
		font-family: 'Nunito Sans';
		text-decoration: underline;
		margin-top: 0.5rem;
		@media screen and (max-width: 767px) {
			font-size: 0.6rem;
		}
	}
	&__badgerow{
		display: flex;
		align-items: center;
		gap: .41rem;
		margin: .4rem 0;
		&--badge{
			width: 30px;
			height: 30px;
		}
	}
}
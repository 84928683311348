@import '../colors';

.ag-center-cols-container > span:last-child{
  max-width: 1920px;
}
.contHomeHero{
  height: 100vh;
	width: 100%;
	overflow: hidden;
	position: relative;
	@media screen and (max-width: 768px) {
		height: 720px;
	}
}

.homeHero {
	display: flex;
	width: 31.771vw;
	flex-direction: column;
	align-items: left;
	z-index: 2;
	margin: 1.823vw 0 0 7.292vw;
	@media screen and (max-width: 768px) {
		width: 64.971vw;
		margin: 1.823vw 0 0 7.192vw;
		margin-top: 10vw;
	}
	
	&__hero {
		position: absolute;
		left: 34.693vw;
		bottom: 0;
		width: 78.349vw;
		height: 62.318vw;
		z-index: -1;
		@media screen and (max-width: 768px) {
			display: none;
		  }
	}
	&__heroMobile {
		position: absolute;
		z-index: -1;
		@media screen and (min-width: 768px) {
			display: none;
		  }
		@media screen and (max-width: 768px) {
			z-index: -1;
			width: 100%;
			height: 779px;
			top: -100px;
		  }
	}
	&__title {
		font-size: 2.865vw;
		color: $primaryColor;
		font-family: 'Nunito Sans';
		margin-bottom: 2.292vw;
		font-weight: 700;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
		  }
	}
	&__list {
		width: 100%;
		margin-bottom: 4.271vw;
		font-size: 1.458vw;
		color: $primaryColor;
		font-family: 'Nunito Sans';
		font-weight: 600;
		@media screen and (max-width: 768px) {
			font-size: 3.933vw;
			
		}
	}
	&__button {
		border-radius: 47px;
		width: 16.927vw;
		height: 3.698vw;
		font-size: 1.302vw;
		font-weight: bold;
		font-family: 'Nunito Sans';
		background-color: $secondaryColor;
		color: white;
		border: none;
		margin-bottom: 3.281vw;
		@media screen and (max-width: 768px) {
			font-size: 3.404vw;
			width: 39vw!important;
			height: 7.698vw!important;
		  }
	}
	&__subtext {
		font-size: 1.302vw;
		font-family: 'Nunito Sans';
		font-weight: 600;
		color: $primaryColor;
		@media screen and (max-width: 768px) {
			font-size: 3.933vw;
			
		}
	}
	&__link {
		font-weight: bold;
		color: $primaryColor;
		font-family: 'Nunito Sans';
		text-decoration: underline;
		@media screen and (max-width: 768px) {
			font-size: 3.933vw;
			
		}
	}
}

@import '../colors';

.jobform {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	&__title {
		font-family: 'Nunito Sans';
		font-weight: 600;
		color: $primaryColor;
		margin-bottom: 2vw;
	}
	&__label {

	}
	&__textinput {
		margin-bottom: 1vw !important;
	}
}
@import '../colors';

.userListingCard {
	padding: 1rem;
	margin: 0 0 1rem 0;
	border: none;
	border-radius: 7px;
	box-shadow: 0 0 15px rgba(0,0,0,0.25);
	&:hover {
		text-decoration: none;
		box-shadow: 0 0 30px rgba(100,125,65,0.5);
	}
	&__name {
		color: $primaryColor;
		font-weight: bold;
		margin: 0;
	}
	&__headline {
		color: $secondaryColor;
		font-weight: bold;
		margin: 0.875rem 0;
	}
	&__description {
		color: $primaryColor;
		margin: 0;
	}
	&__subBox {
		display: flex;
		justify-content: space-between;
		margin: 0.75rem 0 0 0;
	}
	&__rate {
		color: $secondaryColor;
		font-weight: bold;
		margin: 0;
	}
	&__location {
		color: $primaryColor;
		font-weight: bold;
		margin: 0;
	}
	&__badgerow{
		display: flex;
		align-items: center;
		gap: .41rem;
		margin: .4rem 0;
		&--subtitle {
			color: $secondaryColor;
			font-family: 'Nunito Sans';
			font-size: 1.1rem;
			font-weight: 600;
			padding-top: 0.3rem;
			@media screen and (max-width: 767px) {
				font-size: 0.8rem;
			}
		}
		&--badge{
			width: 30px;
			height: 30px;
		}
	}
}
.deleteButton{
	float: right;
	margin-right: 0!important;
}
@import '../colors';

.cleanerdetail {
	display: flex;
	flex-direction: column;
	padding: 3.906vw 7.031vw;
	&__title {
		font-family: 'Jost';
		font-size: 3.344vw;
		font-weight: 600;
		color: $primaryColor;
	}
	&__text {
		font-family: 'Nunito Sans';
		font-size: 1.563vw;
		line-height: 2.344vw;
		@media screen and (max-width: 768px) {
			font-size: 3.204vw;
			line-height: 3.844vw;
		  }
		&--subtitle {
			color: $secondaryColor;
			font-weight: 700;
			margin-top: 2.5rem;
			@media screen and (max-width: 768px) {
				margin-top: 1vw;
				font-size: 3.604vw;
			  }
		}
		&--detail {
			color: $primaryColor;
			font-weight: 600;
			margin-top: 0.5rem;
			@media screen and (max-width: 768px) {
				margin-top: 0;
			  }
		}
	}
}
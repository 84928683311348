@import '../../colors';

.chatmodal{
    position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	background-color: white;
	width: 45vw;
	height: 75vh;
	overflow-y: scroll;
	padding: 2rem;
	@media screen and (max-width: 1023px) {
        width: 65vw;
        height: 75vh;
    }
  @media screen and (max-width: 767px) {
    width: 75vw;
	height: 75vh;
		padding: 1rem;
    }
    &__overlay {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0.7);
		width: 100%;
		height: 100%;
	}
   
}
@import '../colors';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 767px) {
    align-items: inherit;
  }
  &__nav {
    width: 85.417vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 5px solid black;
    @media screen and (max-width: 767px) {
        display: none;
    }
    &--cleanerprofile {
      border-top: none;
      @media screen and (min-width: 767px) {
        border-top: none;
      }
    }
    &--mobile{
      @media screen and (min-width: 767px) {
        display: none;
      }
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 4vw 0;
		    border-top: 5px solid black;
        width: 85.417vw!important;
      }
    }
  }
  &__link {
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 1.302vw;
    color: $primaryColor;
    margin: 0;
    &:hover {
      color: #747474;
      text-decoration: none;
    }
    @media screen and (max-width: 767px) {
      font-size: 1rem !important;
      text-align: center;
      margin: 1vw 0;
    }
    @media screen and (max-width: 1023px) {
      font-size: 0.8rem;
      text-align: center;
      margin: 1vw 0;
    }
  }
  &__logo {
    width: 13.802vw;
    margin: 3.750vw 0 4.427vw 0;
    @media screen and (max-width: 767px) {
      width: 44.802vw;
      margin: 4vw auto 0;
    }
    @media screen and (max-width: 1023px) {
      width: 150px;
    }
  }
  &__subBox {
    width: 100%;
    background-color: #243E4D;
    display: flex;
    justify-content: center;
    padding: 1.458vw 0;
    @media screen and (max-width: 767px) {
      margin-top: 3vw;
    }
  }
  &__trademark {
    font-family: 'Nunito Sans';
    font-weight: 600;
    color: white;
    margin: 0;
  }
  @media screen and (max-width: 767px) {
    font-size: 0.75rem;
  }
}
.tandc {
	padding: 1vw 15vw 4vw;
	&__title {
		font-size: 2.5vw;
		text-align: center;
		margin-bottom: 2vw;
		font-family: "Jost";
		color: #28485B;
		font-weight:600;
		@media screen and (max-width: 768px) {
			font-size: 6.983vw;
		  }
	}
	&__subtitle {
		font-size: 1.5vw;
		font-family: "Jost";
		color: #28485B;
		@media screen and (max-width: 768px) {
			font-size: 4.3vw;
		  }
	}
	&__text {
		font-size: 1.2vw;
		font-family: "Nunito Sans";
		@media screen and (max-width: 768px) {
			font-size: 3vw;
		  }
	}
}
@import '../colors';

.paymentheader {
	background-color: #C1E5EE;
	padding: 4.375vw 0 3.490vw 7.292vw;
	position: sticky;
	top: 0;
	transition: all 0.1s ease;

	&__title {
		font-family: 'Jost';
		font-size: 2.344vw;
		font-weight: 600;
		color: $primaryColor;
		@media screen and (max-width: 768px) {
			font-size: 4.904vw;
	  }
	}
}

.paymentdetail {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4vw 0;
	&__subtitle {
		font-family: 'Jost';
		font-size: 2.344vw;
		font-weight: 600;
		color: $primaryColor;
		margin-bottom: 1.75vw;
		@media screen and (max-width: 768px) {
			font-size: 4.304vw;
	  }
	}
	&__description {
		font-family: 'Nunito Sans';
		font-size: 1.6vw;
		margin-bottom: 2.2vw;
		@media screen and (max-width: 768px) {
			font-size: 4vw;
	  }
	}
	&__cardlist {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-bottom: 5.469vw;
		&--sub {
			justify-content: space-around;
			margin-bottom: 0;
			@media screen and (max-width: 968px) {
				flex-direction: row;
		  }
		}
		@media screen and (max-width: 968px) {
			flex-direction: row;
	  }
	}
	&__button {
		background-color: $primaryColor;
		border-radius: 47px;
		width: 17vw;
		height: 3vw;
		font-size: 1.2vw;
		@media screen and (max-width: 968px) {
      margin-top: 3vw;
      font-size: 3.004vw;
      width: 35vw!important;
      height: 7.698vw!important;
			margin-bottom:5vw ;
    }
	}
}

.paymentcard {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 7px;
	border: none;
	box-shadow: 0 0 15px rgba(0,0,0,0.23);
	width: 29.896vw;
	@media screen and (max-width: 968px) {
		width: 39vw!important;
  }
	// height: 13.698vw;
	padding: 2.083vw 0;
	&--post {
		margin-right: 5.208vw;
	}
	&__title {
		font-family: 'Nunito Sans';
		font-size: 1.563vw;
		font-weight: 700;
		color: $primaryColor;
		@media screen and (max-width: 968px) {
			font-size: 3.446vw;
			margin-top:2vw ;
	  }
	}
	&__rate {
		font-family: 'Nunito Sans';
		font-size: 1.458vw;
		font-weight: 700;
		color: $secondaryColor;
		margin-bottom: 0.5vw;
		&--discount {
			color: #C34545;
		}
		@media screen and (max-width: 968px) {
			font-size: 2.946vw;
	  }
	}
	&__description {
		color: $primaryColor;
		font-family: 'Nunito Sans';
		margin-bottom: 1.5vw;
		font-size: 1.2vw;
		@media screen and (max-width: 968px) {
			font-size: 2.746vw;
	  }
	}
	&__button {
		background-color: #74ABBA;
		border-radius: 47px;
		width: 17.917vw;
		height: 3.698vw;
		font-size: 1.5vw;
		@media screen and (max-width: 968px) {
	    margin-top: 3vw;
	    font-size: 3.004vw;
	    width: 29vw!important;
	    height: 7.698vw!important;
			margin-bottom:2vw ;
    }
	}
}

.paymentform {
	width: 100%;
	height: 50vw;
	background-color: grey;
}

@import '../colors';

.howItWorks {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5.938vw 0;
	&__title {
		font-size: 2.865vw;
		font-family: 'Jost';
		font-weight: 600;
		color: $primaryColor;
		margin: 0 0 6.250vw 0;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
		  }
	}
	&__list {
		width: 90%;
		margin-bottom: 5.469vw;
	}
	&__button {
		border-radius: 47px;
		width: 19.844vw;
		height: 3.698vw;
		font-size: 1.302vw;
		background-color: $primaryColor;
		@media screen and (max-width: 768px) {
			font-size: 3.404vw;
			width: 39vw!important;
			height: 7.698vw!important;
			margin-top: 2vw;
		  }
	}
}

.howCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	&__image {
		height: 8.802vw;
		@media screen and (max-width: 768px) {
			height: 19.333vw;
		}
	}
	&__title {
		font-family: 'Jost';
		font-size: 1.823vw;
		font-weight: 600;
		color: $primaryColor;
		margin: 3.906vw 0 1.367vw 0;
		@media screen and (max-width: 768px) {
			font-size: 4.333vw;
		}
	}
	&__description {
		font-size: 1.302vw;
		text-align: center;

		font-family: 'Nunito Sans';
		font-weight: 600;
		margin: 0;
		@media screen and (max-width: 768px) {
			font-size: 3.533vw;
			margin: 0 10vw ;
			margin-bottom: 3vw;
			
		}
	}
}
@import '../colors';

.assignJob {
	padding: 0 8vw;
	&__titlebox{
		display: flex;
		justify-content: space-between;
		margin: 2vw 0 2vw 0;

	}
	&__title {
		font-family: 'Nunito Sans';
		font-weight: 600;
		color: $primaryColor;
		@media screen and (max-width: 768px) {
			font-size: 6vw;
		}
	}
	&__buttonBox {
		display: flex;
		@media screen and (max-width: 768px) {
			flex-direction: column;
		  }
	}
	&__button {
		background-color: $primaryColor;
		border-radius: 45px;
		border: none;
		width: 15vw;
		height: 3vw;
		color: white;
		font-weight: 600;
		font-size: 1.1vw;
		margin-left: 1vw;
		display: flex;
		align-items: center;
		justify-content: center;
		&--active {
			background-color: #74ABBA;
		}
		@media screen and (max-width: 768px) {
			flex-direction: row;
			font-size: 2.404vw;
			width: 29vw!important;
			height: 5.698vw!important;
			margin-bottom: 2vw;
		  }
	}
	&__list {

	}
	&__emptybox {
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 7px;
		box-shadow: 0 0 15px rgba(0,0,0,0.25);
		padding: 1rem;
		width: 80%;
		height: 10vw;
		margin-left: auto;
		margin-right: auto;
	}
	&__emptytitle {
		color: $primaryColor;
		font-size: 1.4vw;
		font-family: 'Nunito Sans';
		font-weight: 600;
		@media screen and (max-width: 768px) {
			font-size: 3.2vw;
			margin-top: 1vw;
		}
	}
}

.jobCard {
	padding: 2vw;
	margin: 0 0 2vw 0;
	border-radius: 7px;
	box-shadow: 0 0 15px rgba(0,0,0,0.25);
	outline: none;
	border: none;
	
	&--open {
		&:hover {
			box-shadow: 0 0 30px rgba(100,125,65,0.5);
			cursor: pointer;
		}
	}
	&__headline {
		font-weight: 600;
		color: $secondaryColor;
		font-size: 2vw;
		margin-bottom: 1vw;
		@media screen and (max-width: 768px) {
			font-size: 4.5vw;
		}
	}
	&__description {
		color: $primaryColor;
		font-size: 1.5vw;
		margin-bottom: 1.2vw;
		@media screen and (max-width: 768px) {
			width: 80%;
			font-size: 4vw;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3; /* number of lines to show */
			-webkit-box-orient: vertical;
		}
	}
	&__subBox {
		display: flex;
		justify-content: space-between;
	}
	&__rate {
		color: $secondaryColor;
		font-weight: 600;
		font-size: 1.5vw;
		@media screen and (max-width: 768px) {
			font-size: 3.8vw;
		}
	}
	&__location {
		color: $primaryColor;
		font-weight: 600;
		font-size: 1.5vw;
		@media screen and (max-width: 768px) {
			font-size: 3.8vw;
		}
	}
	&__subtitle {
		color: $primaryColor;
		font-weight: 600;
		font-size: 1.6vw;
		margin-bottom: 0.4vw;
		@media screen and (max-width: 768px) {
			font-size: 4vw;
		}
	}
	&__status {
		color: $primaryColor;
		font-size: 1.5vw;
		@media screen and (max-width: 768px) {
			font-size: 4vw;
		}
	}
	&__button {
		background-color: $primaryColor;
		border-radius: 47px;
		height: 2.5vw;
		width: 17vw;
		margin-bottom: 1vw;
		margin-right: 1vw;
		font-size: 1.3vw;
		&--delete {
			background-color: #C34545;
		}
		@media screen and (max-width: 768px) {
			font-size: 3.404vw;
			width: 37vw!important;
			height: 7.698vw!important;
			margin: auto;
			margin-bottom: 3vw;
		  }
	}
	&__buttonContainer{
		display: flex;
		flex-direction: row;
		@media screen and (max-width: 768px){
			flex-direction: column;
		}
		margin: auto;
	}
	&__newMessage{
		background-color: black;
		border-radius: 100%;
		padding: .05rem .7rem;
		margin: 0rem .2rem;
	}
}
@import '../colors';

.reportbox {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1200px;
	padding-left: 2rem;
	@media screen and (max-width: 1023px) {
		padding-left: 0;
	}
}

.userreport {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1200px;
	&__titlebar {
		display: flex;
		justify-content: space-between;
	}
	&__title {
		font-family: 'Nunito Sans';
		font-weight: 600;
		font-size: 1.8rem;
		@media screen and (max-width: 767px) {
			font-size: 0.9rem;
			margin-bottom: 0;
			padding-top: 0.15rem;
		}
	}
	&__subtitle {
		color: $primaryColor;
		font-family: 'Nunito Sans';

		font-size: 1.0rem;
		margin: .5rem 0;
		text-align: left;
	}
	&__selectbox {
		display: flex;
		align-items: center;
	}
	&__label {
		font-size: 1.1rem;
		margin-right: 0.5rem;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
		}
	}
	&__select {
		border: none;
		border-radius: 38px;
		background-color: #74ABBA;
		color: white;
		padding: 0.25rem 0.5rem;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
			padding: 0.2rem 0.4rem;
		}
	}
	&__databox {
		background-color: #E1EFF1;
		padding: 2.188rem 2.852rem;
		margin: 2.618rem 0.821rem 0 0.821rem;
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;
		justify-content: space-between;
		@media screen and (max-width: 767px) {
			flex-direction: column;
			flex-wrap: no-wrap;
			padding: 1rem;
			margin: 1rem 0 0;
			align-items: center;
		}
	}
}

.datacard {
	padding: 1.797rem 0;
	background-color: white;
	border: none;
	border-radius: 3px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16);
	width: 45%;
	height: 220px;
	@media screen and (max-width: 767px) {
		width: 100%;

		max-width: 343px;
	}
	&--bottom {
		
		@media screen and (max-width: 767px) {
			
			margin-bottom: 0;
		}
	}
	&__title {
		color: $primaryColor;
		font-family: 'Jost';
		font-weight: 600;
		font-size: 1.2rem;
		margin-bottom: 0;
	}

	&__number {
		color: $secondaryColor;
		font-family: 'Nunito Sans';
		font-weight: 600;
		font-size: 2.5rem;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}

.affiliates {
	display: flex;
	flex-direction: column;
	background-color: #E1EFF1;
	padding: 2.188rem 2.852rem 0 2.852rem;
	margin: 2.618rem 0.821rem;
	@media screen and (max-width: 767px) {
		margin: 2rem 0;
		padding: 2rem 1rem;
	}
}

.refercard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	background-color: white;
	box-shadow: 0 0px 15px rgba(0,0,0,0.23);
	padding: 2rem 2.2rem;
	margin-bottom: 2.188rem;
	@media screen and (max-width: 767px) {
		padding: 1rem 1rem;
		margin-bottom: 1rem;
	}
	&__topbar {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1.5rem;
		@media screen and (max-width: 767px) {
			margin-bottom: 0.5rem;
		}
	}
	&__user {
		color: $primaryColor;
		font-family: 'Nunito Sans';
		font-weight: 700;
		font-size: 1.5rem;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
		}
	}
	&__text {
		color: $secondaryColor;
		font-family: 'Nunito Sans';
		font-weight: 700;
		font-size: 1.25rem;
		@media screen and (max-width: 767px) {
			font-size: 0.6rem;
			margin-bottom: 0;
		}
	}
}

.adminnav {
	align-self: flex-start;
	display: flex;
	margin-bottom: 2rem;
	@media screen and (min-width: 1024px) {
		display: none;
	}
	&__label {
		font-family: 'Avenir';
		font-weight: 500;
		font-size: 1rem;
		padding-top: 0.2rem;
		margin-right: 0.5rem;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
		}
	}
	&__select {
		border: none;
		border-radius: 38px;
		background-color: #74ABBA;
		color: white;
		padding: 0.25rem 0.5rem;
		font-size: 0.9rem;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
			padding: 0.2rem 0.4rem;
		}
	}
}
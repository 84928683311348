@import '../colors';

body {
	height: 100%;
}
html {
	min-height:100%;
	position: relative;
}

.confirmmodal {
	position: absolute;
	top: 20%;
	left: 29%;
	background-color: white;
	width: 40vw;
	border-radius: 25px;
	@media screen and (max-width: 768px) {
		width: 70vw;
		top: 30%;
		left: 14.7%;
		margin: auto;
	}
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0.7);
		width: 100%;
		height: 100%;
	}
	&__contentbox {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 3.125vw;
		height: 20vw;
		overflow-y: auto;
		@media screen and (max-width: 768px) {
			height: 100%;
		}
	}
	&__text {
		font-family: 'Nunito Sans';
		font-size: 1.8vw;
		color: $primaryColor;
		@media screen and (max-width: 768px) {
			font-size: 6vw;
			text-align: center;
		}
	}
	&__button {
		width: 18.698vw;
		height: 4.271vw;
		border-radius: 49px;
		font-size: 1.406vw;
		font-family: 'Jost';
		font-weight: 600;
		margin: 1vw 0;
		background-color: $primaryColor;
		&--delete {
			background-color: #C34545;
		}
		@media screen and (max-width: 768px) {
			font-size: 5.104vw;
			width: 49vw!important;
			height: 9.698vw!important;
			margin-right: 0;
		  }
	}
}
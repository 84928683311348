@import '../_colors';

.FAQ {
	background-color: #74ABBA;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 7.813vw 9.375vw;
	margin-bottom: 4vw;
	&__title {
		font-family: 'Nunito Sans';
		font-size: 3.333vw;
		font-weight: bold;
		color: #28485b;
		margin-bottom: 2.344vw;
		background-color: white;
		padding: 0% 50vw;
		padding-bottom: 4vw;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
		  }
	}
	.searchBar{
		height: 65px;
		width: 80%;
		padding: 0 2.5vw;
		border-radius: 0%;
		font-size: 1.5vw;
		font-family: 'Nunito Sans';
		border:0;
		border-radius: 50px;
		box-shadow: none!important;
		margin-bottom: 1vw;
		margin-top: 0;
		&:focus{
			outline: none !important;
		}
		@media screen and (max-width: 768px) {
			margin-top: 2vw;
			height: 35px;
			font-size: 3.5vw;
			padding: 0 4vw;
		  }
	}
}
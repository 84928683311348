@import '../colors';

.contact {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5.469vw 0 3.125vw;
	&__title {
		font-family: 'Jost';
		font-weight: 600;
		font-size: 2.604vw;
		color: $primaryColor;
		margin-bottom: 2.506vw;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
			margin-bottom: 6vw;
		  }
	}
}

.contactForm {
	display: flex;
	flex-direction: column;
	width: 42.292vw;
	@media screen and (max-width: 768px) {
		width: 72.292vw;
	}
	&__label {
		font-family: 'Nunito Sans';
		color: $primaryColor;
		font-weight: 900;
		font-size: 1.354vw;
		@media screen and (max-width: 768px) {
			font-size: 3.204vw;
		  }
	}
	&__input {
		height: 3.125vw;
		background-color: #F5F5F5;
		border: none;
		box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
		margin: 0.781vw 0 2.344vw 0;
		padding: 0 1.172vw;
		font-size: 1.354vw;
		font-family: 'Nunito Sans';
		@media screen and (max-width: 768px) {
			height: 9.125vw;
			font-size: 3.004vw;
		  }
		&--area {
			resize: none;
			height: 9.688vw;
			margin-bottom: 3.688vw;
			padding-top: 1.172vw;
			padding-bottom: 1.172vw;
			@media screen and (max-width: 768px) {
				height: 28.125vw;
				font-size: 3.004vw;
			  }
		}
	}
	&__button {
		width: 20.417vw !important;
		height: 4.010vw !important;
		border-radius: 39px;
		font-family: "Jost";
		font-size: 1.563vw;
		font-weight: 600;
		margin: auto;
		background-color: $secondaryColor;
		@media screen and (max-width: 768px) {
			font-size: 3.404vw;
			width: 39vw!important;
			height: 7.698vw!important;
		  }
	}
}
@import '../_colors.sass';
.newUserPromt{
	text-align: center;
	padding: 6vw 0;
	& a{
		color: $secondaryColor;
		&:hover{
			color: black ;
			text-decoration: none;
			cursor: pointer;
		}
	}
}
.findJob {
	margin: 0 !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	&__banner {
	  height: 14.167vw;
	  line-height: 2vw;
	  background-color: #28485b;
	  padding: 7.865vw 0 0 6.927vw;
	  position: sticky;
	  top: 0;
	  transition: all 0.1s ease;
	  z-index: 10;
	  &--scrolling{
		height: 10.167vw;
		padding: 2.865vw 0 0 6.927vw;
	}
	  @media screen and (max-width: 768px) {
		height: 20%;
	}
	
	  width: 100%;
	  & h1 {
	  	vertical-align: middle;
	    color: #fff;
	    font-size: 3vw;
	    font-family: 'Jost';
	    font-weight: 600;
		@media screen and (max-width: 768px) {
			font-size: 7vw;
			margin-bottom: 5vw;
		}
	  }
	}
	&__postBox {
		display: flex;
		flex-direction: row;
		margin-top: 3.906vw;
		margin-bottom: 10vw;
		@media screen and (max-width: 768px) {
			flex-direction: column-reverse;

		}
	}
}
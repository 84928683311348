@import '../colors';
@import '../fonts';

.whyConnect {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #74ABBA;
	padding: 7.813vw 0 0.781vw 0;
	&__title {
		font-family: 'Jost';
		font-size: 2.865vw;
		font-weight: 600;
		color: white;
		@media screen and (max-width: 768px) {
			font-size: 7.904vw;
			text-align: center;
		  }
	}
	&__list {
		list-style-type: none;
		display: flex;
		justify-content: space-around;
		margin: 7.422vw 0;
		width: 100%;
		@media screen and (max-width: 768px) {
			display: flex;
			flex-direction: column;
			width: 100%;
		}
	}
}

.whyCard {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 19.531vw;
	margin: 0 1rem;
	position: relative;
	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		width: 80%;
		text-align: center;
		align-items: center;
		margin: auto;
	}
	&> h3,p {
		color: white;
	}
	&__image {
		width: 8.333vw;
		@media screen and (max-width: 768px) {
			width: 20.333vw;
		}
		&--trust {
			width: 7.865vw !important;
			@media screen and (max-width: 768px) {
				width: 20.333vw!important;
			}
		}
		&--easytouse {
			position: relative;
			left: 1.7vw;
		}
		&--transparency {
			position: relative;
			left: 1vw;
		}
		
	}
	&__title {
		font-family: 'Jost';
		font-size: 1.823vw;
		font-weight: 600;
		margin: 2.188vw 0 1.563vw;
		@media screen and (max-width: 768px) {
			font-size: 5.333vw;
		}
	}
	&__description {
		font-family: 'Jost';
		font-size: 1.302vw;
		font-weight: medium;
		line-height: 1.979vw;
		text-align: center;
		@media screen and (max-width: 768px) {
			font-size: 4.333vw;
			margin-bottom: 8vw;
			line-height: 5vw;
			font-weight: 500;
		}
	}
}
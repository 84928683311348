@import '../../../colors';

    

.filter-BG
{   background-color: #E1EFF1;
    width: 25.781vw;
    //height: 140.542vw;
    height: 100%;
    padding: 1vw 0;
    @media screen and (max-width: 768px) {
		display: none;
	}
}

    

.filterOptions
    {margin: 2%;}
.filterSearch
    {padding: 2% 5%;
    margin-top: 1.344vw;}
.filterSearch__title
    {font-size: 1.250vw;}
.filterSearch__inputBox
    {display: flex;
    align-items: center;
    margin-top: 0.938vw;}
.filterSearch__checkBox
    {width: 1.510vw;
    height: 1.406vw;}
.filterSearch__postalBox
    {display: flex;
    flex-direction: column;}
.filterSearch__postalInput
    {width: 50%;
    font-size: 1.146vw;
    border: none;
    padding: 0.5vw;}
.filterSearch__postalInputBox
    {width: 100%;
    height: 3.073vw;
    display: flex;
    flex-wrap: no-wrap;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    margin-bottom: 1vw;}
.filterSearch__division
    {width: 1px;
    height: 2.0vw;
    background-color: black;}
.filterSearch__distance
    {width: 50%;
    border: none;
    font-size: 1.146vw;
    padding: 0.5vw;}
.search
    {width: 100%;
    height: 3.073vw;
    font-size: 1.146vw;
    padding: 0.5vw;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1vw;}
.search--active
    {background-color: blue;
    color: white;}
.search__button 
    {background-color: $primaryColor;
    border-radius: 47px;
    width: 9vw;
    height: 2.5vw;
    font-size: 1.2vw;
    margin-right: 1vw;
    margin-top: 2vw;}
label
    {padding-left: .3vw;
    font-size: 1.1vw;
    display: inline-block;
    margin: 0;
    font-family: 'Jost';}

.setBudgetButton
    {width: 12vw;
    height: 2.5vw;
    border-radius: 47px;
    align-self: center;
    margin: 0.3vw 0 1.5vw 0;
    font-size: 1.2vw;
    font-weight: 600;
    margin-top: 1vw;
    background-color: $primaryColor;}

.budgetForm
    {display: flex;
    flex-direction: column;}
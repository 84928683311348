@import './colors';

.nexusNav{
    background-color: rgba(0,0,0,0.0);
    padding: 4.479vw 7.292vw;
    width: 100%;
    @media screen and (max-width: 767px) {
        background-color: rgba(0,0,0,0.0);
      }
}
   
.nexusLogo
{
    width: 20.5vw;
    margin-right: 1vw;
    @media screen and (max-width: 767px) {
        width: 60.5vw;

      }
}
    
.navLinks
{
    font-size: 1.3vw;
    text-decoration: none;
    text-align: left;
    font-weight: 700;
    vertical-align: middle;
    font-family: 'Nunito Sans';
    margin-left: 1vw;
    color: black!important;
    @media screen and (max-width: 767px) {
        font-size: 4.404vw;
        line-height: 7vw;
      }
    &:hover{
        color: grey!important;  
    }
          
}
.navDesktop{
    @media screen and (max-width: 767px) {
        display: none;
      }
}

.navMobile{
    @media screen and (min-width: 768px) {
        display: none;
      }
}

.getStartedButton
   {
    align-items: center;
    font-size: 1.4vw;
    font-weight: bold;
    color: $white;
    background: $secondaryColor;
    border-radius: 47px;
    height: 2.969vw;
    width: 12.708vw;
    border: 0;
    font-family: 'Nunito Sans';
    margin-left: 1vw;   
    @media screen and (max-width: 767px) {
        font-size: 4.404vw;
        width: 39vw!important;
        height: 8.698vw!important;
        margin-top: 2vw;
      }
}


@import '../colors';

.similaruser {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 6.250vw 0 9.375vw;
	&--noauth {
		height: 35vw;
	}
	&__title {
		font-family: 'Jost';
		font-size: 2.344vw;
		font-weight: 600;
		margin-bottom: 3.125vw;
		@media screen and (max-width: 768px) {
			font-size: 4.904vw;
		  }
		
	}
	&__list {
		width: 70vw;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__emptybox {
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 7px;
		box-shadow: 0 0 15px rgba(0,0,0,0.25);
		padding: 1rem;
		width: 80%;
		height: 10vw;
	}
	&__emptytitle {
		color: $primaryColor;
		font-family: 'Nunito Sans';
		font-weight: 600;
		@media screen and (max-width: 768px) {
			font-size: 2.75vw;
			margin: auto;
		}
	}
}
@import '../colors';

.adminreviewmodal {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	background-color: white;
	width: 900px;
	height: 440px;
	overflow-y: scroll;
	padding: 2rem;
	@media screen and (max-width: 1023px) {
		width: 700px;
		height: 70%;
  }
  @media screen and (max-width: 767px) {
		width: 300px;
		height: 50%;
		padding: 1rem;
		display: flex;
		align-items: center;
  }
  &__na {
  	font-family: 'Jost';
  	color: $primaryColor;
  	margin-top: 120px;
  	@media screen and (max-width: 767px) {
			margin-top: 3.5rem;
			font-size: 1.3rem;
		}
  }
  &__nabutton {
  	margin-top: 1rem;
  	width: 150px;
  	height: 40px;
  	border-radius: 38px;
  	background-color: $primaryColor;
  	@media screen and (max-width: 767px) {
			width: 120px;
			height: 30px;
			font-size: 0.8rem;
		}
  }
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0.7);
		width: 100%;
		height: 100%;
	}
	&__contentbox {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}

.adminreviewcard {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	box-shadow: 0 0 15px rgba(0,0,0,0.23);
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	&__titlebox {
		display: flex;
		justify-content: space-between;
		width: 100%;
		@media screen and (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start;
	  }
	}
	&__title {
		font-family: 'Nunito Sans';
		color: $primaryColor;
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: 0;
	  @media screen and (max-width: 767px) {
			font-size: 0.9rem;
	  }
	}
	&__email {
		font-family: 'Nunito Sans';
		color: $secondaryColor;
		font-size: 1.2rem;
		font-weight: 600;
		margin-bottom: 0;
		padding-top: 0.3rem;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
	  }
	}
	&__text {
		font-family: 'Nunito Sans';
		color: $primaryColor;
		margin: 1rem 0;
		text-align: left;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
			margin: 0.5rem 0;
	  }
	}
	&__button {
		background-color: rgba(0,0,0,0);
		color: #C34545;
		text-decoration: underline;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
	  }
	}
}

.adminjobcard{
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	box-shadow: 0 0 15px rgba(0,0,0,0.23);
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	&__titlebox {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 1rem;
		@media screen and (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 0.5rem;
	  }
	}
	&__title {
		font-family: 'Nunito Sans';
		color: $primaryColor;
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: 0;
		@media screen and (max-width: 767px) {
			font-size: 0.9rem;
	  }
	}
	&__email {
		font-family: 'Nunito Sans';
		color: $secondaryColor;
		font-size: 1.2rem;
		font-weight: 600;
		margin-bottom: 0;
		padding-top: 0.3rem;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
	  }
	}
	&__text {
		font-family: 'Nunito Sans';
		color: $primaryColor;
		margin: 1rem 0;
		text-align: left;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
			margin: 0.5rem 0;
	  }
	}
	&__location {
		font-family: 'Nunito Sans';
		font-weight: 600;
		font-size: 1rem;
		color: $primaryColor;
		margin: none !important;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
			margin-top: 0.5rem;
	  }
	}
	&__button {
		background-color: rgba(0,0,0,0);
		color: #C34545;
		text-decoration: underline;
		@media screen and (max-width: 767px) {
			font-size: 0.7rem;
	  }
	}
}
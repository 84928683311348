@import '../colors';

.createjob {
	display: flex;
	flex-direction: column;
	width: 55.521vw;
	@media screen and (max-width: 768px) {
		width: 75.521vw;
	}
	&__titlebox{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__title {
		font-family: 'Jost';
		font-size: 2.865vw;
		font-weight: 600;
		color: $primaryColor;
		@media screen and (max-width: 768px) {
			font-size: 5vw;
		}
	}
	&__desc {
		font-size: 1.465vw;
		@media screen and (max-width: 768px) {
			font-size: 3vw;
		}
	}
	&__subtitle {
		font-family: 'Nunito Sans';
		font-weight: 600;
		font-size: 1.8vw;
		color: $primaryColor;
		margin: 1.5vw 0 .5vw 0;
		@media screen and (max-width: 768px) {
			font-size: 3.5vw;
		}

	}
	&__locationtitle {
		font-family: 'Nunito Sans';
		font-weight: 300;
		font-size: 1.719vw;
		color: $primaryColor;
		margin: 3.385vw 0 2.865vw 0;
		@media screen and (max-width: 768px) {
			font-size: 4vw;
		}
	}
	&__label {
		font-family: 'Jost';
		font-size: 1.510vw;
		color: $primaryColor;
		font-weight: 300;
		margin-bottom: 1.042vw;
		&--title {
			margin: 3vw 0 0 3.516px;
		}
		@media screen and (max-width: 768px) {

			font-size: 4.5vw;
			
		}
	}
	&__checklabel {
		font-family: 'Jost';
		font-size: 1.4vw;
		@media screen and (max-width: 768px) {
			font-size: 3.7vw;
		}
	}
	&__textinput {
		margin-bottom: 0.990vw;
		width: 100%;
		height: 3.333vw;
		border-radius: 3px;
		border: 1px solid #707070;
		padding: 0.5vw;
		font-size: 1.4vw;
		@media screen and (max-width: 768px) {
			font-size: 3.1vw;
			height: 5.333vw;
		}
		&--active {
			background-color: blue;
			color: white;
		}
		&--rate {
			width: 50%;
			padding: 0.5vw 0.8vw 0.5vw 1.9vw;
			height: 100%;
			border-radius: 3px 0 0 3px;
			text-align: right;
			font-size: 1.75vw;
			@media screen and (max-width: 768px) {
				width: 80%;
				height: 10vw;
				font-size: 3.1vw;
				padding-right: 2vw;
			}
		}
		&--area {
			height: 10vw;
			@media screen and (max-width: 768px) {
				
				height: 25vw;
			}
		}
	}
	&__ratebox {
		position: relative;
		width: 25.417vw;
		display: flex;
		height: 5.365vw;
		margin: 1.5vw 0;
		@media screen and (max-width: 768px) {
			width: 80%;
			height: 10vw;
		}
	}
	&__rate {
		width: 50%;
		background-color: #EBEBEB;
		border-radius: 0 3px 3px 0;
		border: 1px solid #707070;
		border-left: none;
		display: flex;
		align-items: center;
		padding: 1vw;
		@media screen and (max-width: 768px) {
			width: 20%;
		}
	}
	&__rateunit {
		margin: 0;
		font-size: 1.302vw;
		font-family: 'Nunito Sans';
		@media screen and (max-width: 768px) {
			font-size: 2.402vw;
		}
	}
	&__unit {
		position: absolute;
		left: 0.8vw;
		top: 1.25vw;

		font-size: 1.771vw;
		@media screen and (max-width: 768px) {
			font-size: 5.402vw;
		}
	}
	&__checkContainer {
		display: flex;
		align-items: center;
	}
	&__checkinput {
		width: 1.4vw;
		height: 1.4vw;
		@media screen and (max-width: 768px) {
			width: 3.333vw;
			height: 3.333vw;
		}
		
	}
	&__select {
		width: 12.708vw;
		height: 5.365vw;
		background-color: #EBEBEB;
		padding: 0 0 0 1.615vw;
		border-radius: 3px;
		border: 1px solid #707070;
		margin: 1.823vw 0 4.167vw 0;
		font-size: 1.302vw;
		-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") #EBEBEB no-repeat 96% !important;
		@media screen and (max-width: 768px) {
			width: 80%;
			height: 8.333vw;
			font-size: 3.402vw;
		}
	}
	&__button {
		background-color: $primaryColor;
		border-radius: 4vw;
		height: 3vw;
		width: 18vw;
		font-size: 1.2vw;
		margin-top: 1vh;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2vw;
		font-family: "Nunito Sans";
		@media screen and (max-width: 768px) {
			font-size: 3.404vw;
			width: 39vw!important;
			height: 7.698vw!important;
		  }
		  &__disabled{
			pointer-events: none;
			cursor: not-allowed;
			opacity: 0.65;
			filter: alpha(opacity=65);
			-webkit-box-shadow: none;
			box-shadow: none;
		  }
	}
	.subscript{
		font-size: 50%;
	}
}
/* Removes Arrow from input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


@import '../colors';

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	list-style-type: none;
}

.endorsements {
	background-color: #C1E5EE;
	display: flex;
	justify-content: center;
	padding: 4.688vw 0 7.813vw;
	@media screen and (max-width: 768px) {
		font-size: 2.904vw;
		line-height: 3.844vw;
	  }
	&__contentBox {
		width: 68.359vw;
		@media screen and (max-width: 768px) {
			width: 90.359vw;
		  }
	}
	&__title {
		font-family: 'Jost';
		font-weight: 600;
		font-size: 2.344vw;
		color: $primaryColor;
		@media screen and (max-width: 768px) {
			font-size: 6.904vw;
			text-align: center;
		  }
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		margin-top: 2.500vw;
		@media screen and (max-width: 768px) {
			flex-direction:column;
			align-items: center;
		  }
	}
}

.endorseCard {
	display: flex;
	align-items: center;
	padding: 0 1.563vw;
	margin: 0.781vw 0;
	background-color: $primaryColor;
	border-radius: 35px;
	width: 20.219vw;
	height: 3.229vw;
	@media screen and (max-width: 768px) {
		flex-direction:column;
		width: 60.219vw;
		height: 15.229vw;
		margin-bottom: 3vw;;
	  }
	&__title {
		color: white;
		width: 100%;
		text-align: center;
		font-size: 1.042vw;
		padding-top: .5vw;
		@media screen and (max-width: 768px) {
			padding-top: 2.5vw;
			font-size: 3.542vw;
			
		  }
		
	}
	&__voteBox {
		display: flex;
		align-items: center;
	}
	&__button {
		border: none;
		background-color: rgba(0,0,0,0);
	}
	&__icon {
		height: 0.781vw;
		width: 0.625vw;
		@media screen and (max-width: 768px) {
			height: 3.081vw;
			width: 3.025vw;
			
		  }
	}
	&__voteCount {
		color: white;
		margin: 0 0.625vw;
		font-size: 1.042vw;
		@media screen and (max-width: 768px) {
			font-size: 2.542vw;
			margin: 0 5vw;
		  }
	}
}
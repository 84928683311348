
.listingContainer
{
    box-shadow: 0 0 15px rgba(0,0,0,0.23);
    margin-top: 2.604vw;
    padding: 2.240vw 3.021vw 1.875vw 2.604vw;
    border-radius: 7px;
    @media screen and (max-width: 768px) {
        height: 27.563vw;
        width: 90vw;
        padding: 2.240vw 3.021vw 1.875vw 2.604vw;
        margin: auto;
        margin-top: 2.604vw;
	}
    &:hover
        {box-shadow: 0 0 30px rgba(100,125,65,0.5);
        cursor: pointer;}
}
.listingContainer__link
{
    &:hover{
        text-decoration: none;
    }
}

.listingName
{
    color: #28485b;
    font-size: 1.6vw;
    font-family: 'Nunito Sans';
    font-weight: 600;
    @media screen and (max-width: 768px) {
        font-size: 4.2vw;
	}
}
.listingJobTitle
{
    color: #647d41;
    font-size: 1.4vw;
    margin: 0.9vw 0 1.2vw 0;
    font-family: 'Nunito Sans';
    font-weight: 600;
    @media screen and (max-width: 768px) {
        font-size: 3.9vw;
        height: 3.563vw;
        line-height: 2.563vw;
	}
}
.listingJobDesc
{
        font-size: 1.250vw;
    line-height: 1.563vw;
    margin: 0 0 1.5vw 0;
    font-family: 'Nunito Sans';
    height: 1.563vw;
    color: #243E4D;
    //for ellipsis
    // display: block
    // display: -webkit-box
    // height: 4.689vw
    // margin: 0 auto
    // -webkit-line-clamp: 3
    // -webkit-box-orient: vertical
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 768px) {
        font-size: 3.9vw;
        height: 4.963vw;
        line-height: 4.963vw;
        margin-top: 1vw;
	}
}

.listingSubBox
{
   display: flex;
    justify-content: space-between;
    margin-top: 1.2vw;
}
.listingCost{
      color: #647d41;
    font-size: 1.250vw;
    font-weight: 600;
    font-family: 'Nunito Sans';
    @media screen and (max-width: 768px) {
        font-size: 3.1vw;
        height: 4.263vw;
        line-height: 4.263vw;
        margin-bottom: 0;
	}
}

.listingSFL
{
    color: #000;
    font-size: 1.250vw;
    font-family: 'Nunito Sans';
    font-weight: 600; 
    @media screen and (max-width: 768px) {
        margin-bottom: 0;
        font-size: 3.1vw;
        height: 4.263vw;
        line-height: 4.263vw;
        text-align: right;
	}
}
.profilePosts
{
    width: 100%;
    padding: 5.573vw 7.292vw 0;
    @media screen and (max-width: 768px) {
        padding: 0;
    }
}
.posts
{
    width: 56.667vw;
    margin-left: 2.969vw;
    @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0vw;
	}
    &__sortbox {
        display: flex;
        align-items: center;
    }
    &__sortlabel {
        font-size: 1.3vw;
        margin-right: 0.5vw;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}
.posts__title
{
    font-size: 2.083vw;
    font-family: 'Nunito Sans';
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
        font-size: 5.304vw;
        margin-left: 0vw;
    }
}
.posts__titleProfile
{
    font-size: 2.083vw;
    font-family: 'Nunito Sans';
    font-weight: 600;
    @media screen and (max-width: 768px) {
        font-size: 5.304vw;
        margin-top: 2vw;
        margin-left: 5vw;
    }
}
.posts__titlebox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // @media screen and (max-width: 768px) {
    //     flex-direction: column;
    // }
}
.posts__pageBox
{
    display: flex;
    justify-content: center;
    margin: 3.333vw 0;
    @media screen and (max-width: 768px) {
        margin-top: 5vw;
	}
}

.posts__pageLink
{
    width: 5.677vw;
    height: 2.188vw;
    border: none;
    background-color: #608A95;
    padding: 0.2rem;
    margin: 0;
    font-size: 0.885vw;
    font-family: 'Nunito Sans';
    color: #FFFFFF;
    font-weight: 600;
    @media screen and (max-width: 1023px) {
        width: 70px;
        height: 30px;
        font-size: 0.8rem;
	}
    @media screen and (max-width: 767px) {
        width: 65px;
        height: 25px;
        font-size: 0.6rem;
    }
}
.posts__pageLink--left
{
    border-radius: 2.344vw 0 0 2.344vw;
}
.posts__pageLink--right
{
    border-radius: 0 2.344vw 2.344vw 0;
}
.posts__emptybox
{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 7px;
    box-shadow: 0 0 15px rgba(0,0,0,0.25);
    width: 80%;
    height: 10vw;
    margin-top: 4vw;
    margin-left: auto;
    margin-right: auto;
}
.posts__emptytitle
{
    font-family: 'Nunito Sans';
    font-weight: 600;
    @media screen and (max-width: 768px) {
        margin-top: 1.8vw;
        font-size: 5vw;
	}
}
.posts__displayPage
{
    width: 12vw;
    height: 2.188vw;
    background-color: #74ABBA;
    margin: 0;
    color: #FFFFFF;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.885vw;
    font-family: 'Nunito Sans'; 
    @media screen and (max-width: 1023px) {
        width: 120px;
        height: 30px;
        font-size: 0.8rem;
	}
    @media screen and (max-width: 767px) {
        width: 100px;
        height: 25px;
        font-size: 0.6rem;
    }
}
.posts__sortBy{
    text-align: right;
    font-size: 1.3vw;
    margin-left: 0;
    padding: 0.2vw 0 0.2vw 0.3vw;
    @media screen and (max-width: 768px) {
        // margin-left: 70%;
        text-align: right;
        font-size: 3.1vw;
	}  
}

@import '../colors';

body {
	height: 100%;
}
html {
	min-height: 100%;
	position: relative;
}

.editjobmodal {
	overflow-y: scroll;
	position: absolute;
	top: 10%;
	left: 20%;
	background-color: white;
	width: 60vw;
	max-height: 80vh;
	padding: 0 1.5vw;
	@media screen and (max-width: 768px) {
		width: 70vw;
		top: 30%;
		left: 14.7%;
		margin: auto;
	}
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,0.7);
		width: 100%;
		height: 100%;
	}
}
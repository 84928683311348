@font-face {
	font-family: 'Jost';
	src: url("./Fonts/Jost-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: 'Jost';
	src: url("./Fonts/Jost-Regular.ttf");
	font-weight: 400;
}
@font-face {
	font-family: 'Jost';
	src: url("./Fonts/Jost-Bold.ttf");
	font-weight: 600;
}
@font-face {
	font-family: 'Jost';
	src: url("./Fonts/Jost-ExtraBold.ttf");
	font-weight: 700;
}
@font-face {
	font-family: 'Nunito Sans';
	src: url("./Fonts/NunitoSans-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: 'Nunito Sans';
	src: url("./Fonts/NunitoSans-Regular.ttf");
	font-weight: 400;
}
@font-face {
	font-family: 'Nunito Sans';
	src: url("./Fonts/NunitoSans-Bold.ttf");
	font-weight: 600;
}
@font-face {
	font-family: 'Nunito Sans';
	src: url("./Fonts/NunitoSans-ExtraBold.ttf");
	font-weight: 700;
}